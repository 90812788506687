import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAllStatusList } from "../../services/statusManagement.service";

export const getStatusManagementTableList = createAsyncThunk(
  "get/product/list",
  async (_, { rejectWithValue }) => {
    try {
      const res = await getAllStatusList();
      return res;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
